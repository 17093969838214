import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createWalletTransfer,
  fetchProfile,
  // createGoldTransfer,
  // fetchConversionRateStart,
  fetchWallets,
  getAccountsStart
} from "store/actions";
import Loader from "components/Common/Loader";
import { fetchConversionRateStart } from "store/general/conversionRates/actions";
import { enableClientToClientTransfer } from "config";
import { getTradingAccountByLogin } from "apis/forex/accounts";
import SearchableClient from "./SearchableClient";
import { getCustomerAssets } from "apis/forex/requests";
import { getConversionRate } from "apis/conversionRate";
import VerifyOTP from "components/Common/VerifyOTP";
import TransactionLimits from "./TransactionLimits";

export default function TransferModal({ isOpen, toggle: toggleModal }) {

  const [from, setFrom] = React.useState(undefined);
  const [to, setTo] = React.useState(undefined);
  const [differentCurrency, setDifferentCurrency] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  const [isFromInvestor, setIsFromInvestor] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { _id = "" } = useSelector((state) => state?.Profile?.clientData);

  const { settings = {} } = useSelector((state) => state?.Profile?.clientData);
  const { transferLimit = 0, transferLimitCurrency = "", transferredAmount = 0 } = settings;
  const { wallets, loading: walletLoading, clearingCounter, transferLoading } = useSelector((state) => state?.walletReducer);
  const { accounts, loading: accountLoading } = useSelector((state) => state.forex?.accounts);
  const { conversionRate = 1, loading: conversionLoading } = useSelector((state) => state?.conversionReducer);
  const [type, setType] = React.useState("");
  const [clientAccount, setClientAccount] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [toOptions, setToOptions] = React.useState([]);
  const [isOTPVerified, setIsOTPVerified] = React.useState(false);
  const [disableTransfer, setDisableTransfer] = React.useState(false);
  const toggle = () => {
    setFrom(undefined);
    setTo(undefined);
    setAmount(0);
    setLoader(false);
    setType("");
    setClientAccount(null);
    setClient(null);
    toggleModal();
  };
  const transferOptions = [
    {
      label: t("Self"),
      value: "self"
    }
  ];
  if (enableClientToClientTransfer) {
    transferOptions.push(
      {
        label: t("Client - Search By Name"),
        value: "clientName"
      }, {
        label: t("Client - Search By Account Number"),
        value: "clientAccount"
      }
    );
  }
  useEffect(() => {
    dispatch(getAccountsStart({
      limit: 100,
    }));
  }, []);

  useEffect(() => {
    dispatch(fetchWallets({
      isDemo: false,
    }));
  }, []);

  useEffect(() => {
    if (from !== undefined && to !== undefined) {
      if (from.currency !== to.currency) {
        setDifferentCurrency(true);
      } else {
        setDifferentCurrency(false);
      }
    }
    if (from === undefined || to === undefined) {
      setDifferentCurrency(false);
    }
  }, [from, to]);

  useEffect(() => {
    if (isOpen && transferLoading) {
      toggle();
    }
  }, [clearingCounter, transferLoading]);

  useEffect(() => {
    if (differentCurrency) {
      dispatch(fetchConversionRateStart({
        from: from.currency,
        to: to.currency
      }));
    }
  }, [differentCurrency, from, to]);

  const handleOnSelect = (selection, field) => {
    if (field === "from") {
      setFrom(selection);
    } else {
      setTo(selection);
    }
  };


  const getFromOptions = () => {
    const options = [];
    if (wallets.length > 0) {
      const filteredWallets = wallets.filter((wallet) => wallet?._id !== to?.id && !wallet.isInventory && !wallet.isCrypto);
      filteredWallets.forEach((wallet) => {
        options.push({
          label: `${wallet?.isFeesWallet ? `Fee Wallet - ${(wallet.asset)} | Profile - ${wallet?.profileId?.username}` : (wallet.asset)} - ${wallet.amount?.toFixed(2)}`,
          value: {
            isWallet: true,
            id: wallet._id,
            currency: wallet?.isIb ? "USD" : wallet.asset,
            amount: wallet.amount
          },
          disabled: wallet?.withdrawalDisabled ?? false,
        });
      });
    }
    if (accounts !== null && accounts.length > 0) {
      const filteredAccounts = accounts.filter((account) => account._id !== to?.id && !["DEMO"].includes(account.type));
      filteredAccounts?.filter((acc) => acc?.accountTypeId?.type === "MAM_MASTER" ? acc?.profile?.profileType === 9 : true)?.forEach((account) => {
        options.push({
          label: `${account?.login} | ${account?.accountTypeId?.title} ${account?.accountTypeId?.type} - ${account.currency} - ${account?.Balance}`,
          value: {
            isWallet: false,
            platform: account.platform,
            id: account._id,
            currency: account.currency,
            amount : account.Balance,
          },
          disabled: account.withdrawalDisabled ?? false
        });
      });
    }
    return options;
  };

  const getToOptions = () => {
    if (from === undefined) return [];
    const options = [];
    if (accounts !== null && accounts.length > 0) {
      const filteredAccounts = accounts.filter(
        (account) => account._id !== from?.id && !["DEMO", "MAM_FEE"].includes(account.type) && (from?.platform === account.platform || from.isWallet)
      );
      filteredAccounts?.filter((acc) => acc?.accountTypeId?.type === "MAM_MASTER" ? acc?.profile?.profileType === 9 : true).forEach((account) => {
        options.push({
          label: `${account?.login} | ${account?.accountTypeId?.title} ${account?.accountTypeId?.type} - ${account.currency} - ${account?.Balance}`,
          value: {
            isWallet: false,
            id: account._id,
            currency: account.currency,
            amount: account.Balance,
          },
          // disabled: account.transferDisabled ?? false
        });
      });
    }
    if (wallets.length > 0) {
      const filteredWallets = wallets.filter((wallet) => wallet._id !== from.id && !wallet.isInventory && !wallet.isCrypto && !wallet?.isFeesWallet);
      filteredWallets.forEach((wallet) => {
        options.push({
          label: `${wallet.asset} - ${wallet.amount?.toFixed(2)}`,
          value: {
            isWallet: true,
            id: wallet._id,
            currency: wallet?.isIb ? "USD" : wallet.asset,
            amount: wallet.amount
          },
          // disabled: wallet?.transferDisabled ?? false
        });
      });
    }
    return options;
  };

  useEffect(() => {
    if (type === "self") {
      setToOptions(getToOptions());
      setIsOTPVerified(true);
    } else if (type === "clientName" && client?._id) {
      setIsOTPVerified(false);
      setToOptions([]);
      setLoader(true);
      getCustomerAssets(client?._id).then((res) => {
        setToOptions(res?.result?.map(asset => {
          if (asset?.isWallet) {
            return {
              label: `${asset.asset}`,
              value: {
                isWallet: true,
                id: asset._id,
                currency: asset.asset,
                amount: asset.amount
              },
              // disabled: asset?.transferDisabled ?? false
            };
          } else {
            return {
              label: `${asset?.login} ${asset?.platform} - ${asset.currency}`,
              value: {
                isWallet: false,
                id: asset._id,
                currency: asset.currency,
                amount: asset.Balance
              },
              // disabled: asset?.transferDisabled ?? false
            };
          }
        }));
      }).catch((err) => {
      }).finally(() => {
        setLoader(false);
      });
    }
  }, [type, client]);

  useEffect(() => {    
    if (from) {
      if (from.isWallet) {
        setIsFromInvestor(false);
      } else {
        const account = accounts.find((account) => account._id === from.id);
        setIsFromInvestor(account?.type === "INVESTOR");
      }
    }
  }, [from]);

  useEffect(() => {
    if (type === "self") {
      setIsOTPVerified(true);
    } else {
      setIsOTPVerified(false);
    }
  }, [type]);

  useEffect(() => {
    if (from && to && !from?.isWallet && !to?.isWallet) {
      setDisableTransfer(true);
      setErrMsg("Internal Transfers Between Trading Accounts Are Not Allowed!!");
    } else {
      setDisableTransfer(false);
      setErrMsg("");
    }
  }, [from, to]);
  
  const handleSubmit = (e, values) => {
    const data = {
      fromId: values.from.id,
      toId: values?.to?.id ?? to?.id,
      baseCurrency: from.currency,
      targetCurrency: to.currency,
      amount: values.amount,
      note: values.note,
    };
    if (from.isWallet && to.isWallet) {
      data.source = "WALLET";
      data.destination = "WALLET";
    } else if (!from.isWallet && !to.isWallet) {
      data.source = "FX";
      data.destination = "FX";
    } else if (from.isWallet && !to.isWallet) {
      data.source = "WALLET";
      data.destination = "FX";
    } else if (!from.isWallet && to.isWallet) {
      data.source = "FX";
      data.destination = "WALLET";
    }
    dispatch(createWalletTransfer({
      ...data,
      isClientToClient: type !== "self",
    }));
    dispatch(fetchProfile());
    setIsOTPVerified(false);
  };

  const loadTradingAccount = (login) =>{
    setLoader(true);
    getTradingAccountByLogin({
      payload: {
        login,
      }
    }).then((res) => {
      setLoader(false);
      if (res?.result?.docs) {
        if (res.result?.docs?.[0]?.type === "MAM_MASTER" && res?.result?.docs?.[0]?.profile?.profileType === 9) {
          setClient({
            label: `${res?.result?.docs?.[0].customerId?.firstName}`,
            value: res?.result?.docs?.[0].customerId,
          });
          setTo({
            isWallet: false,
            platform: res.result.docs[0].platform,
            id: res.result.docs[0]._id,
            currency: res.result.docs[0].currency,
            amount: res.result.docs[0].Balance
          });
          setErrMsg("");
        } else if (res?.result?.docs?.[0]?.type !== "MAM_MASTER") {
          setClient({
            label: `${res?.result?.docs?.[0].customerId?.firstName}`,
            value: res?.result?.docs?.[0].customerId,
          });
          setTo({
            isWallet: false,
            platform: res.result.docs[0].platform,
            id: res.result.docs[0]._id,
            currency: res.result.docs[0].currency,
            amount: res.result.docs[0].Balance
          });
          setErrMsg("");
        } else {
          setErrMsg("Invalid Account - Can't transfer to this account");
          setClient();
          setTo();
          setLoader(false);
        }
      }
    }).catch((err) => {
      console.log(err);
      setClient();
      setTo();
      setErrMsg("Invalid Account - Can't transfer to this account");
      setLoader(false);
    });
  };

  useEffect(() => {
    if (type === "clientAccount" && clientAccount?.toString().length > 4) {
      loadTradingAccount(clientAccount);
    }
  }, [type, clientAccount]);

  const [convertedLimit, setConvertedLimit] = useState(0);

  useEffect(() => {
    if (from?.currency && to?.currency) {
      setLoader(true);
      getConversionRate({
        baseCurrency: transferLimitCurrency,
        targetCurrency: from.currency,
      }).then((res) => {
        const availableLimit = transferLimit - transferredAmount;
        setConvertedLimit(parseFloat(res * availableLimit));
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoader(false);
      });
    }
  }, [from, to, transferLimitCurrency]);


  const isAmountValid = () => {
    if (type === "self") {
      return parseFloat(from?.amount) >= parseFloat(amount);
    }
    if (parseFloat(from?.amount) >= parseFloat(amount) && parseFloat(amount) <= convertedLimit) {
      return true;
    }
    return false;
  };
  const isDisabled = !to || !isOTPVerified || !isValid || !isAmountValid() || disableTransfer;

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} >
      <ModalHeader className="d-flex flex-column gap-3" toggle={toggle}>
        {t("Internal Transfer")}
      </ModalHeader>
      {
        (walletLoading || accountLoading) ? <Loader/> : (
          <ModalBody  style={{
            margin: "1rem"
          }}>
            <AvForm onValidSubmit={(e, v) => handleSubmit(e, v)} >
              <div className="mb-3">
                <AvFieldSelecvt
                  name="from"
                  label={t("From")}
                  options={getFromOptions()}
                  value={from}
                  onChange={(e) => handleOnSelect(e, "from")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "From is required"
                    },
                  }}
                />
              </div>
              <Row className="mb-3">
                <AvFieldSelecvt
                  type="select"
                  name="type"
                  label={t("Type")}
                  value={type}
                  options={transferOptions}
                  onChange={(e) => setType(e)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Type is required"
                    },
                  }}
                />
              </Row>
              {
                type && type !== "self" && (
                  <>
                    {
                      type === "clientAccount" && (
                        <>
                          <div className="mb-3">
                            <AvField
                              type="text"
                              name="clientAccount"
                              label={t("Client Account")}
                              value={clientAccount}
                              onChange={(e) => setClientAccount(e.target.value)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Client Account is required"
                                },
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              type="text"
                              name="clientName"
                              label={t("Client Name")}
                              value={client?.label}
                              disabled
                            />
                          </div>
                        </>
                      )
                    }
                    {
                      type === "clientName" && (
                        <div className="mb-3">
                          <SearchableClient customer={client} setCustomer={setClient} disallowed={_id} />
                        </div>
                      )
                    }
                  </>
                )
              }
              {
                type !== "clientAccount" && <div className="mb-3">
                  <AvFieldSelecvt
                    name="to"
                    label={t("To")}
                    value={to}
                    options={toOptions}
                    onChange={(e) => handleOnSelect(e, "to")}
                    disabled={type === "clientAccount"}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "To is required"
                      },
                    }}
                  />
                </div>
              }
              {
                differentCurrency && (
                  conversionLoading ? <Loader/> : (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t("You have selected different currencies. Conversion rate will be applied.")}
                        {amount > 0 && ` ${amount} ${from?.currency} = ${(amount * conversionRate)?.toFixed(3)} ${to?.currency}`}
                      </Alert>
                    </div>
                  )
                )
              }
              <div className="mb-3">
                <AvField
                  name="amount"
                  label={from ? `${t("Amount")} (${from.currency})` : t("Amount")}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Amount is required"
                    },
                    min: {
                      value: 10,
                      errorMessage: "Amount must be greater than 10"
                    },
                    max: {
                      value: from?.amount,
                      errorMessage: "Amount must be less than or equal to available balance"
                    }
                  }}
                />
              </div>
              <div className="mb-3">
                <AvField
                  name="note"
                  label={t("Note")}
                  type="text"
                />
              </div>
              {
                enableClientToClientTransfer && type !== "self" && settings && <Alert className="text-center"  color="warning">
                  {t("Your daily limit is ")}{parseInt(transferLimit )} {transferLimitCurrency}. {t("Your can transfer ")} {(transferLimit - transferredAmount - amount) > 0 ? (transferLimit - transferredAmount - amount) : 0} {transferLimitCurrency} {t("to other Clients today.")}
                </Alert>
              }
              {/* <Row>
                <TransactionLimits
                  amt={amount}
                  isValid={isValid}
                  baseCurrency={from?.currency}
                  setIsValid={setIsValid}
                  isFromSelected={from !== undefined}
                  isFx={from?.isWallet ? false : true}
                  isInternalTransfer={true}
                />
              </Row> */}
              {
                enableClientToClientTransfer && type === "clientAccount" && errMsg !== "" && <Alert className="text-center" color="danger">
                  {errMsg}
                </Alert>
              }
              <div className="mb-3">
                {
                  (amount > 0 && to && from) && (
                    <Alert className="text-center">
                      {!differentCurrency ? `Total credit Amount: ${amount} ${from?.currency}` : `Total credit Amount: ${(amount * conversionRate)?.toFixed(3)} ${to?.currency}`}
                    </Alert>
                  )
                }
              </div>
              {
                !isOTPVerified &&  <VerifyOTP
                  isOTPVerified={isOTPVerified}
                  setIsOTPVerified={setIsOTPVerified}
                />
              }
              {
                disableTransfer && <Alert className="text-center" color="danger">
                  {errMsg}
                </Alert>
              }
              <div className="mb-3 text-center">
                {
                  (conversionLoading || loader) ? <Loader/> : (
                    <Button className="color-bg-btn border-0 w-75" type="submit" disabled={isDisabled}>
                      {t("Submit")}
                    </Button>
                  )
                }
              </div>
            </AvForm>
          </ModalBody>
        )
      }
    </Modal>
  );
}
